#exitcapture-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    @include color(background-color, 'body-75');
}

.exitcapture-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5rem;

    .modal {
        max-width: 40rem;
        width: 100%;
        position: relative;
        @include color(background-color, 'background');

        .modal-content {
            padding: 5rem 3.5rem 4rem 3.5rem;

            @include breakpoint(XS) {
                padding: 5rem 1.5rem 2rem 1.5rem;
            }
        }
        .exitcapture-close {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
        }
    }
}