//Type scale for a 14px base size with a 1.25 ratio
$type-scale: (
    "sizes": (
        "XS": 0.7144rem,
        "S": 0.8572rem,
        "M": 1rem,
        "L": 1.2857rem,
        "XL": 1.5714rem,
        "2XL": 1.9286rem,
        "3XL": 2.4286rem,
    ),
    "line-heights": (
        "XS": 1rem,
        "S": 1.25rem,
        "M": 1.5rem,
        "L": 1.75rem,
        "XL": 2rem,
        "2XL": 2.5rem,
        "3XL": 3rem,
    ),
    "scale-ratio": 1.25,
);