.footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include color(background-color, 'background');

    @include breakpoint(XS) {
        padding-top: 2.75rem;
        padding-bottom: 2.75rem;
    }
}

.social-links {
    .button {
        margin: 0 0.5rem;
    }
}

.footer-logo {
    img,
    svg {
        width: 16rem;
        height: 6rem;
    }
}