.gallery {
    margin-top: 5rem;
    margin-bottom: 4.125rem;

    @include breakpoint(XS) {
        margin-top: 0;
    }
    &.grid {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(XS) {
            display: block;
        }
        .grid-item {
            display: block;
            position: relative;
            margin: 0.5em;
            height: 22rem;
            width: calc(33.333% - 1rem);
            overflow: hidden;

            @include breakpoint(S) {
                width: calc(50% - 1rem);
            }
            @include breakpoint(XS) {
                height: 16rem;
                margin: 1rem 0;
                width: 100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @extend %slow-transition;
            }
            &:after {
                content:"";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @extend %transition;
            }
            &.overlay-active {
                .grid-item-caption {
                    opacity: 1;
                }
                &:hover {
                    figure {
                        .grid-item-caption {
                            background: rgba(#000, 0.3);
                        }
                    }
                }
            }
            &:hover {
                .grid-item-caption {
                    opacity: 1;
                }
                img {
                    transform: scale(1.05);
                }
                .caption-text,
                .expand-icon {
                    transform: translateY(0);
                }
            }
            figure {
                margin-bottom: 0;

                .grid-item-caption {
                    @extend %transition;
                    background: rgba(#000, 0.5);
                    text-align: center;
                    color: #fff;
                    opacity: 0;
                    position: absolute;
                    padding: 1em;
                    top: 0;
                    height: 100%;
                    left: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
                .caption-text {
                    @extend %transition;
                    margin-bottom: 1rem;
                    font-family: $font-stack-primary;
                    font-size: 1.25em;
                    position: relative;
                    width: 100%;
                    z-index: 1;
                    transform: translateY(0.5rem);
                }
                .expand-icon {
                    @extend %transition;
                    position: relative;
                    z-index: 1;
                    padding: 1rem;
                    border-radius: 100%;
                    border: 2px solid #fff;
                    transform: translateY(0.5rem);

                    svg {
                        display: block;
                    }
                }
                .arrow-icon {
                    padding: 0;
                    border: 0;
                    @extend %nav-transition;
                }
            }
            &.category-target.fade-out {
                opacity: 0;
            }
            &.category-target.filtered {
                display: none;
            }
        }
    }
}

.gallery-wrapper {
    margin-bottom: 5em;
}

.gallery-sidebar-edge-vert {
    position: absolute;
    top: 0;
    right: calc(100% - 10px);
    display: block;
    z-index: 2;

    @include breakpoint(S) {
        display: none;
    }
}

.gallery-sidebar-edge-horz {
    display: none;
    position: absolute;
    bottom: calc(100% - 12px);
    left: 0;
    width: 100%;
    z-index: 2;

    @include breakpoint(S) {
        display: block;
    }
}